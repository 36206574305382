import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import Image from '~components/Image'
import RichText from '~components/RichText'
import Button from '~components/Button'
import AnimateInView from '~components/AnimateInView'

const TextImage = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<ImageCol reverse={content.reverse}>
				<AnimateInView 
					threshold={0.5}
					variants={{
						visible: { opacity: 1,	scale: 1, rotate: 0 },
						hidden: { opacity: 0 , scale: 0.9, rotate: 10}
					}}>
					<StyledImage image={content.image}/>
				</AnimateInView>
			</ImageCol>
			<Content reverse={content.reverse}>
				<Title>{content.title}</Title>
				<Text><RichText content={content.text}/></Text>
				<Button color={content?.button?.color} link={content.button.link}>{content.button.text}</Button>
			</Content>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin: var(--xl) 0;
`
const ImageCol = styled.div`
	grid-column: ${props => props.reverse ? '7/13' : '1/7'};
	grid-row: 1;
	${mobile}{
		grid-row: 1;
		grid-column: span 12;
		margin-bottom: var(--l)
	}
`
const StyledImage = styled(Image)`
	border-radius: 10px;
	overflow: hidden;
	
`
const Content = styled.div`
	grid-column: ${props => props.reverse ? '2/6' : '8/12'};
	grid-row: 1;
	align-self: center;
	${mobile}{
		grid-row: 2;
		grid-column: span 12;
	}
`
const Title = styled.h3`
	margin-bottom: var(--m);
`
const Text = styled.div`
	margin-bottom: var(--m);
`

TextImage.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TextImage