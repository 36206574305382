import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet, desktop, SliderCaption, ImageSlide} from '~styles/global'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import PageHeader from '~components/PageHeader'
import Slider from '~components/Slider'
import Section from '~components/Section'
import RichText from '~components/RichText'
import Button from '~components/Button'
import Image from '~components/Image'
import TextImage from '~components/TextImage'
import AnimateInView from '~components/AnimateInView'

const About = ({ data }) => {
	const { page } = data
	const [currentSlide, setCurrentSlide] = useState(0)

	return (
		<>
			<Global styles={css`
				body{
						background-color: var(--white);
					}
				`}
			/>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<Header image={page?.headerImage} title={page?.headline} text={page?.introText} />
			<SliderSection>
				<SliderSecLeft>
					<SliderSecTitle>{page?.featuredSliderTitle}</SliderSecTitle>
					<SliderSecText><RichText content={page.featuredSliderText}/></SliderSecText>
					<SliderSecButton 
						link={page.featuredSliderButton?.link} 
						color={page.featuredSliderButton?.color}
					>
						{page.featuredSliderButton?.text}
					</SliderSecButton>
				</SliderSecLeft>
				<SliderSecRight>
					<StyledSlider onChange={slideNumber => setCurrentSlide(slideNumber)}>
						{page?.featuredSlider?.slides?.map((slide, i) => (
							<ImageSlide key={`slide ${i}`} image={slide} aspectRatio={1.263}/>
						))}
					</StyledSlider>
					<SliderCaption>
						<RichText content={page.featuredSlider?.slides[currentSlide].caption} />
					</SliderCaption>
				</SliderSecRight>
			</SliderSection>
			<div>
				{page?.textImageBlocks?.map(block => (
					<TextImage key={block.title} content={block}/>
				))}
			</div>
			{page.showTeamSection &&
				<Team>
					<TeamTitle>Team Members</TeamTitle>
					{page?.teamMembers?.map(( member, i )=> (
						<TeamMember 
							key={member._key}
						>
							<AnimateInView 
								variants={{
									visible: { 
										opacity: 1,	
										scale: 1, 
										rotate: 0,
										y: 0,
										transition: {
											delay: i * 0.2,
											duration: 0.6
										} 
									},
									hidden: { opacity: 0 , scale: 0.9, y: 100}
								}}
							>
								<div>
									<MemberImage image={member.image}/>
									<MemberName>{member.name}</MemberName>
									<MemberRole>{member.role}</MemberRole>
								</div>
							</AnimateInView>
						</TeamMember>
					))}
				</Team>
			}
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Header = styled(PageHeader)`
	
`
const SliderSection = styled(Section)`
	background-color: var(--lightBlue);
	padding: var(--xl) 0;
`
const SliderSecLeft = styled.div`
	align-self: center;
	grid-column: 2/6;
	${mobile}{
		grid-column: span 12;
		margin-bottom: var(--l);
	}
`
const SliderSecTitle = styled.h3`
	margin-bottom: var(--m);
`
const SliderSecText = styled.div`
	margin-bottom: var(--m);
`
const SliderSecButton = styled(Button)`
	
`
const SliderSecRight = styled.div`
	grid-column: 7/12;
	${mobile}{
		grid-column: span 12;
	}
`
const StyledSlider = styled(Slider)`
	
`
const Team = styled(Section)`
	background-color: var(--lightBlue);
	padding: var(--xl) 0;
`
const TeamTitle = styled.h3`
	grid-column: span 12;
	text-align: center;
	margin-bottom: var(--l);
	max-width: 100%;
`
const TeamMember = styled.div`
	grid-column: span 3;
	${tablet}{
		grid-column: span 6;
		margin-bottom: var(--m);
	}
`
const MemberImage = styled(Image)`
	margin-bottom: var(--m);
	border-radius: 10px;
	overflow: hidden;
`
const MemberName = styled.div`
	
`
const MemberRole = styled.div`
	
`

export const query = graphql`
  query AboutQuery($id: String)  {
    page: sanityAbout(id: {eq: $id}) {
			title
			seo{
				...seo
			}
      headerImage {
				...imageWithAlt
			}
			headline
			introText: _rawIntroText(resolveReferences: {maxDepth: 5}) 
			featuredSliderTitle
			featuredSliderText: _rawFeaturedSliderText(resolveReferences: {maxDepth: 5}) 
			featuredSliderButton {
				...button
			}
			featuredSlider{
				slides {
					...imageWithCaption
				}
			}
			textImageBlocks {
				image {
					...imageWithAlt
				}
				button {
					...button
				}
				reverse
				title
				text: _rawText(resolveReferences: {maxDepth: 5})
			}
			showTeamSection
			teamMembers{
				image {
					...imageWithAlt
				}
				name
				role
				_key
			}
    }
  }
`

About.propTypes = {
	data: PropTypes.object
}

export default About